import { NgClass, NgIf } from '@angular/common';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { RecaptchaModule } from 'ng-recaptcha';
import { ConfirmationService, MessageService } from 'primeng/api';
import { environment } from '../../../environments/environment';
import { PrimeNGModule } from '../../primeng.module';
import { AuthService } from '../../services/auth/auth.service';
import { ToastService } from '../../services/toast/toast.service';
import { UserService } from '../../services/user/user.service';
import User from '../../shared/models/user/user';

export class UserLoginError {
  public errorEmail = false;
  public errorEmailNew = false;
  public errorPassword = false;
  public errorPasswordConfirm = false;
}

@Component({
  selector: 'app-login-customer',
  standalone: true,
  imports: [NgIf, NgClass, PrimeNGModule, RecaptchaModule],
  templateUrl: './login-customer.component.html',
  styleUrl: './login-customer.component.scss',
  providers: [ToastService, MessageService],
})
export class LoginCustomerComponent {
  public user: User = new User();
  public userError: UserLoginError = new UserLoginError();
  public firstStep = true;
  public secondStep = false;
  public captcha = false;
  public siteKey = environment.siteKeyGoogle;

  constructor(
    private authService: AuthService,
    private confirmationService: ConfirmationService,
    public router: Router,
    private toastService: ToastService,
    private userService: UserService
  ) {}

  async onClickLogin() {
    this.userError.errorEmail = !this.user?.email;
    this.userError.errorPassword = !this.user?.password;

    if (this.userError?.errorEmail || this.userError?.errorPassword) {
      this.toastService.error('Preencha os campos obrigatórios!');
      return;
    }

    if (!this.captcha) {
      this.toastService.error(
        'Faça a validação de anti-spam e tente novamente!'
      );
      return;
    }

    try {
      await this.authService.login(this.user);
    } catch (error: any) {
      this.toastService.error(error);
    }
  }

  async onClickContinue() {
    try {
      this.userError.errorEmailNew = !this.user.email;
      if (this.userError.errorEmailNew) {
        this.toastService.error('Informe o e-mail corretamente!');
        return;
      } else {
        await this.userService.validateEmail(
          encodeURIComponent(this.user.email || '')
        );
        this.router.navigate(['novo-cadastro/' + this.user.email]);
      }
    } catch (e) {
      this.firstStep = false;
    }
  }

  resolvedcCaptcha(captchaResponse: string | null) {
    this.captcha = captchaResponse ? true : false;
  }

  async onClickForgot() {
    this.userError.errorEmail = !this.user?.email;
    if (this.userError.errorEmail) {
      this.toastService.error(
        'Informe seu email ou seu CPF para recuperar a senha!'
      );
      return;
    }

    this.confirmationService.confirm({
      message: 'Deseja mesmo solicitar uma nova senha?',
      header: 'Confirmação',
      accept: async () => {
        try {
          await this.userService.forgot(this.user.email || '');
          this.toastService.success(
            'Você receberá um e-mail com as instruções para gerar uma nova senha!'
          );
        } catch (error: any) {
          this.toastService.error(error);
        }
      },
    });
  }
}
