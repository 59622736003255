<app-breadcrumb
  *ngIf="showBreadcrumbCat"
  [subCategoryNew]="subCategory"
  [showSubCategoryNew]="true"
></app-breadcrumb>

<div class="categories-main" *ngIf="showBreadcrumbCat">
  <div class="container">
    <div class="categories-main-content">
      <a
        [routerLink]="item?.id?.toLowerCase()"
        class="categories-main-content-item"
        *ngFor="let item of models"
      >
        {{ item.description }}
      </a>
    </div>
  </div>
</div>

<router-outlet (activate)="setConfig($event)"></router-outlet>
