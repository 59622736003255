import { HttpHeaders, HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { environment } from '../../../environments/environment';
import { InfoCategory } from '../../shared/models/app/info-category';
import { BlindsOptionsEnum } from '../../shared/models/app/info-enum';
import Budget from '../../shared/models/budget/budget';
import { Cart, CartFull, CartItem } from '../../shared/models/cart/cart';
import CartCheckoutDetails from '../../shared/models/cart/cart-checkout-details';
import CartFilter from '../../shared/models/cart/cart-filter';
import PrepareCheckoutCart from '../../shared/models/cart/prepare-checkout-cart';
import Coupon from '../../shared/models/coupons/coupon';
import CouponDiscount from '../../shared/models/coupons/coupon-discount';
import Freight from '../../shared/models/freight/freight';
import FreightSelected from '../../shared/models/freight/freight-selected';
import { Product } from '../../shared/models/product/product';
import { StorageConstants } from '../../shared/models/storage/storage-constants';
import User from '../../shared/models/user/user';
import { ValueUtils } from '../../shared/utils/value';
import { AppInfoService } from '../app-info/app-info.service';
import { AuthService } from '../auth/auth.service';
import { BudgetService } from '../budget/budget.service';
import { GoogleAnalyticsService } from '../google/google-analytics.service';
import { HttpService } from '../http/http.service';
import { LocalStorageService } from '../local-storage/local-storage.service';
import { ProductsService } from '../product/products.service';

export interface CartState {
  showCart: boolean;
  search: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class CartService {
  public user: User | undefined = new User();
  public searchProductsCart$ = new BehaviorSubject<CartState>({
    showCart: false,
    search: false,
  });

  private authService = inject(AuthService);

  constructor(
    private appInfoService: AppInfoService,
    private budgetService: BudgetService,
    private googleAnalyticsService: GoogleAnalyticsService,
    private httpService: HttpService,
    private localStorageService: LocalStorageService,
    private productService: ProductsService,
    public router: Router
  ) {
    this.authService.userData$.subscribe((res: any) => {
      this.user = undefined;
      if (res) {
        this.user = new User();
        this.user.fromJson(res);
      }
    });
  }

  async init(): Promise<void> {
    await this.productService.getValueMotorBando();
  }

  public get(noLoader: boolean): Promise<any> {
    let headers = noLoader
      ? new HttpHeaders({
          'no-loader': 'no-loader',
        })
      : new HttpHeaders();

    return new Promise((resolve, reject) => {
      this.httpService.get('cart/', {} as HttpParams, headers).subscribe({
        next: (res: any) => {
          resolve(res);
        },
        error: (error: any) => {
          const msg =
            error?.error?.message ||
            error?.error?.error ||
            'Ocorreu um erro ao buscar o carrinho!';
          reject(msg);
        },
      });
    });
  }

  public save(items: Array<any>): Promise<any> {
    return new Promise((resolve, reject) => {
      this.httpService
        .post('cart/', {
          cartProducts: items?.map((i) => ({
            amount: i?.amount,
            idProduct: i?.productId || i?.product.id,
            controlSide: i?.controlSide,
            drive: i?.drive,
            panel: i?.panel,
            typeFixing: i?.typeFixing,
            customHeight: i?.customHeight,
            customWidth: i?.customWidth,
          })),
        })
        .subscribe({
          next: (res: any) => {
            resolve(res);
          },
          error: (error: any) => {
            const msg =
              error?.error?.message ||
              error?.error?.error ||
              'Ocorreu um erro ao salvar o carrinho!';
            reject(msg);
          },
        });
    });
  }

  public async getOrCreateCart(
    items: Array<CartItem>,
    noLoader: boolean
  ): Promise<any> {
    try {
      return await this.get(noLoader);
    } catch (err) {
      if (items?.length > 0) {
        await this.save(items);
        return await this.get(noLoader);
      } else {
        console.log(err);
      }
    }
  }

  public saveItem(item: any, cart: Cart): Promise<any> {
    return new Promise((resolve, reject) => {
      this.httpService
        .post('cart/add/product/', {
          amount: item?.amount,
          idProduct: item?.productId || item?.product?.id,
          id: cart?.id,
          controlSide: item?.controlSide,
          drive: item?.drive,
          panel: item?.panel,
          typeFixing: item?.typeFixing,
          customHeight: item?.customHeight,
          customWidth: item?.customWidth,
        })
        .subscribe({
          next: (res: any) => {
            resolve(res);
          },
          error: (error: any) => {
            const msg =
              error?.error?.message ||
              error?.error?.error ||
              'Ocorreu um erro ao salvar o item no carrinho!';
            reject(msg);
          },
        });
    });
  }

  async getItems(idCart: string | null = null): Promise<Array<CartItem>> {
    let cartItens: Array<CartItem> = [];
    try {
      let itens;
      if (idCart) {
        itens = await this.prepareBudget(idCart);
      } else {
        if (this.user) {
          const cart = await this.get(false);
          itens = cart?.cartProducts;
        } else {
          itens = await this.getItemsLocalStorage();
        }
      }
      if (itens?.length > 0) {
        cartItens = await this.parseCartItem(itens);
      }
      return cartItens;
    } catch (error: any) {
      return [];
    }
  }

  async parseCartItem(itens: any[]): Promise<Array<CartItem>> {
    const persianas: InfoCategory =
      await this.appInfoService.getCategoryPersianas();
    const cartItens: Array<CartItem> = [];
    itens.forEach((element: any) => {
      const product: Product = new Product();
      product.fromJson(element?.product, persianas);
      const cartItem: CartItem = {
        product,
        productId: product?.id,
        amount: element?.amount,
        amountOld: element?.amount,
        id: element?.id,
        customHeight: element?.customHeight,
        customWidth: element?.customWidth,
        controlSide: element?.controlSide,
        controlSideDescription:
          element?.controlSide === BlindsOptionsEnum.CONTROL_SIDE_LEFT
            ? 'Esquerdo'
            : element?.controlSide === BlindsOptionsEnum.CONTROL_SIDE_RIGHT
            ? 'Direito'
            : '',
        drive: element?.drive,
        driveDescription:
          element?.drive === BlindsOptionsEnum.DRIVE_MOTOR
            ? 'Motorizado(' +
              ValueUtils.currency(this.productService.getValueMotor()) +
              ')'
            : element?.drive === BlindsOptionsEnum.DRIVE_MANUAL
            ? 'Manual'
            : '',
        panel: element?.panel,
        panelDescription:
          element?.panel === BlindsOptionsEnum.WITH_PANEL
            ? 'Com Bandô(' +
              ValueUtils.currency(
                this.productService.getValueTotalBando(
                  element?.customWidth || 0,
                  product?.subCategory || ''
                )
              ) +
              ')'
            : element?.panel === BlindsOptionsEnum.WITHOUT_PANEL
            ? 'Sem Bandô'
            : '',
        typeFixing: element?.typeFixing,
        typeFixingDescription:
          element?.typeFixing === BlindsOptionsEnum.TYPE_FIXING_WALL
            ? 'Na parede'
            : element?.typeFixing === BlindsOptionsEnum.TYPE_FIXING_CEILING
            ? 'No teto'
            : '',
      };
      cartItens.push(cartItem);
    });
    return cartItens;
  }

  async addItem(item: CartItem, setQuantity: boolean, showCartHeader: boolean) {
    try {
      let itemAlreadyAdded = false;
      let itensLocalStorage: any[] = [];
      let currentCart;
      let itens;
      if (this.user) {
        try {
          currentCart = await this.get(true);
        } catch (error) {
          await this.save([item]);
          currentCart = await this.get(true);
          itemAlreadyAdded = true;
          this.googleAnalyticsService.sendEventAttToCart(item);
          this.updateCartState(true, showCartHeader);
        }

        if (!itemAlreadyAdded) {
          itens = await this.parseCartItem(currentCart?.cartProducts);
          const exist = itens.find((i: any) => this.productsEquals(item, i));

          if (exist) {
            const index = currentCart?.cartProducts?.findIndex(
              (p: any) => p?.product?.id === item?.productId
            );

            if (setQuantity) {
              const newAmount = (exist.amount || 0) + 1;

              if (
                newAmount <= (item?.product?.stockBalance || 0) ||
                item?.product?.canBeGeneratedWithStockZero
              ) {
                item.amount = newAmount;
              } else {
                throw `Quantidade informada não disponível. Existe(m) apena(s) ${item?.product?.stockBalance} item(s) disponível(is)`;
              }
            }

            currentCart?.cartProducts?.splice(index, 1);
            exist.amount = item.amount;
            if (exist.id) {
              await this.deleteProduct(exist.id);
              if (exist.amount) {
                if (exist?.amount > (exist?.amountOld || 0)) {
                  this.googleAnalyticsService.sendEventAttToCart(item);
                }
                await this.saveItem(exist, currentCart);
              }
              this.updateCartState(true, showCartHeader);
            } else {
              await this.saveItem(item, currentCart);
              this.updateCartState(true, showCartHeader);
            }
          } else {
            if (
              (item.amount || 0) <= (item?.product?.stockBalance || 0) ||
              item?.product?.canBeGeneratedWithStockZero
            ) {
              this.googleAnalyticsService.sendEventAttToCart(item);
              await this.saveItem(item, currentCart);
              this.updateCartState(true, showCartHeader);
            } else {
              throw `Quantidade informada não disponível. Existe(m) apena(s) ${item?.product?.stockBalance} item(s) disponível(is)`;
            }
          }
        }
      } else {
        const itens = await this.getItemsLocalStorage();
        if (itens && itens.length > 0) {
          itensLocalStorage = [...itens];
        }

        const exist = itensLocalStorage.find((i) =>
          this.productsEquals(item, i)
        );

        if (exist) {
          const index = itensLocalStorage?.findIndex((i) =>
            this.productsEquals(item, i)
          );

          if (setQuantity) {
            item.amount = exist.amount + 1;
          }

          if (
            (item?.amount || 1) > (item?.product?.stockBalance || 0) &&
            !item?.product?.canBeGeneratedWithStockZero
          ) {
            throw `Quantidade informada não disponível. Existe(m) apena(s) ${item?.product?.stockBalance} item(s) disponível(is)`;
          } else {
            itensLocalStorage.splice(index, 1);
          }

          if ((item?.amount || 0) > 0) {
            itensLocalStorage.push(item);
          }
        } else {
          if (item?.amount !== 0) {
            if (
              (item?.amount || 1) <= (item?.product?.stockBalance || 0) ||
              item?.product?.canBeGeneratedWithStockZero
            ) {
              itensLocalStorage.push(item);
              this.googleAnalyticsService.sendEventAttToCart(item);
            } else {
              throw `Quantidade informada não disponível. Existe(m) apena(s) ${item?.product?.stockBalance} item(s) disponível(is)`;
            }
          }
        }
        await this.localStorageService.set(
          StorageConstants.AMBIENTA_CART_ITEMS,
          itensLocalStorage
        );
        this.updateCartState(true, showCartHeader);
      }
    } catch (error) {
      throw error;
    }
  }

  productsEquals(itemNew: CartItem, itemList: CartItem) {
    if (itemNew.product?.productPerSquareMeter) {
      const equal =
        itemNew?.productId === itemList?.productId &&
        itemNew?.controlSide == itemList?.controlSide &&
        itemNew?.customHeight == itemList?.customHeight &&
        itemNew?.customWidth == itemList?.customWidth &&
        itemNew?.drive == itemList?.drive &&
        itemNew?.panel == itemList?.panel &&
        itemNew?.typeFixing == itemList?.typeFixing;
      return equal;
    } else {
      return itemNew?.productId === itemList?.productId;
    }
  }

  public async checkout(freightSelected: FreightSelected): Promise<boolean> {
    const cart: Cart = await this.getOrCreateCart([], false);
    const cartCheckout: PrepareCheckoutCart = new PrepareCheckoutCart();
    cartCheckout.cartId = cart.id;
    cartCheckout.freightSelected = freightSelected;
    return new Promise((resolve, reject) => {
      this.httpService.post('cart/checkout/', cartCheckout).subscribe({
        next: (res: any) => {
          resolve(res);
        },
        error: (error: any) => {
          const msg =
            error?.error?.message ||
            error?.error?.error ||
            'Ocorreu um erro ao realizar o checkout!';
          reject(msg);
        },
      });
    });
  }

  public async prepareCheckout(
    freightSelected: FreightSelected
  ): Promise<CartCheckoutDetails> {
    const persianas: InfoCategory =
      await this.appInfoService.getCategoryPersianas();
    const cart: Cart = await this.getOrCreateCart([], true);
    const cartCheckout: PrepareCheckoutCart = new PrepareCheckoutCart();
    cartCheckout.cartId = cart?.id;
    cartCheckout.freightSelected = freightSelected;
    if (cartCheckout?.freightSelected?.optionFreight === Freight.STORE_PICKUP) {
      cartCheckout.freightSelected.freight = undefined;
    }
    return new Promise((resolve, reject) => {
      this.httpService.post('cart/prepare-checkout/', cartCheckout).subscribe({
        next: (res: any) => {
          const newCart: CartCheckoutDetails = new CartCheckoutDetails(
            res,
            persianas,
            this.productService
          );
          resolve(newCart);
        },
        error: (error: any) => {
          let msg =
            error?.error?.message ||
            error?.error?.error ||
            'Ocorreu um erro ao realizar o checkout! Entre em contato conosco.';
          msg = msg.replace(
            'br.com.rmecommercebackend.exception.RmEcommerceException:',
            ''
          );
          reject(msg);
        },
      });
    });
  }

  public async list(filter: CartFilter): Promise<Array<CartFull>> {
    return new Promise((resolve, reject) => {
      this.httpService.post('cart/pageable/list/', filter).subscribe({
        next: (res: any) => {
          resolve(res?.content);
        },
        error: (error: any) => {
          const msg =
            error?.error?.message ||
            error?.error?.error ||
            'Ocorreu um erro ao buscar os carrinhos!';
          reject(msg);
        },
      });
    });
  }

  public delete(id: number): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.httpService.delete(`cart/${id}`).subscribe({
        next: (res: any) => {
          this.updateCartState(true, false);
          resolve(res);
        },
        error: (error: any) => {
          const msg =
            error?.error?.message ||
            error?.error?.error ||
            'Ocorreu um erro ao deleter o carrinho!';
          reject(msg);
        },
      });
    });
  }

  public coupon(idCart: number, coupon: string): Promise<CouponDiscount> {
    return new Promise((resolve, reject) => {
      this.httpService
        .post(`cart/${idCart}/store/coupon/${coupon}/`, null)
        .subscribe({
          next: (res: any) => {
            const cd: CouponDiscount = Object.assign(new CouponDiscount(), res);
            cd.coupon = Object.assign(new Coupon(), cd.coupon);
            resolve(cd);
          },
          error: (error: any) => {
            const msg =
              error?.error?.message ||
              error?.error?.error ||
              'Ocorreu um erro ao buscar o cupom!';
            reject(msg);
          },
        });
    });
  }

  async prepareBudget(hash: string): Promise<any> {
    try {
      const budget: Budget = await this.budgetService.get(hash);
      const items = budget?.cart?.cartProducts || [];
      await this.localStorageService.set(
        StorageConstants.AMBIENTA_ZIP_CODE,
        budget.zipCode
      );
      await this.localStorageService.removeItem(
        StorageConstants.AMBIENTA_CART_ITEMS
      );

      if (this.user) {
        let cart = await this.get(false);
        if (cart) {
          await Promise.all(
            cart.cartProducts.map((item: any) => this.deleteProduct(item.id))
          );
          await Promise.all(items.map((item) => this.saveItem(item, cart)));
        } else {
          cart = await this.save(items);
        }
      } else {
        const parseItens = await this.parseCartItem(items);
        await this.localStorageService.set(
          StorageConstants.AMBIENTA_CART_ITEMS,
          parseItens
        );
        this.updateCartState(true, false);
      }
      return items;
    } catch (err) {
      console.log(err);
    }
  }

  public deleteProduct(id: number): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.httpService.delete(`cart/remove/product/${id}`).subscribe({
        next: (res: any) => {
          resolve(res);
        },
        error: (error: any) => {
          const msg =
            error?.error?.message ||
            error?.error?.error ||
            'Ocorreu um erro ao deleter o produto!';
          reject(msg);
        },
      });
    });
  }

  async checkCartItems(): Promise<boolean> {
    let hasNewProducts = false;
    return new Promise(async (resolve, reject) => {
      let cart: any;
      try {
        cart = await this.get(false);
      } catch (error) {
        cart = null;
      }

      try {
        const itensLocal = await this.getItemsLocalStorage();
        if (itensLocal?.length > 0) {
          const itensNew: Array<CartItem> = [...itensLocal];
          if (cart) {
            if (cart?.cartProducts?.length > 0) {
              hasNewProducts = true;
            }
            await Promise.all(
              itensNew.map(async (item) => await this.saveItem(item, cart))
            );
          } else {
            await this.save(itensNew);
          }
          await this.localStorageService.removeItem(
            StorageConstants.AMBIENTA_CART_ITEMS
          );
        }
        this.updateCartState(true, false);
        resolve(hasNewProducts);
      } catch (error: any) {
        reject(error);
      }
    });
  }

  async getItemsLocalStorage(): Promise<Array<any>> {
    const carts = await this.localStorageService.get(
      StorageConstants.AMBIENTA_CART_ITEMS
    );
    return carts || [];
  }

  async deleteCarts(carts: Array<CartFull>) {
    try {
      await Promise.all(carts.map((c: any) => this.delete(c.id)));
    } catch (err) {
      console.error(err);
    }
  }

  updateCartState(search: boolean, showCart: boolean): void {
    const currentState = this.searchProductsCart$.getValue();
    this.searchProductsCart$.next({
      ...currentState,
      showCart,
      search,
    });
  }

  getSearchProductsCart() {
    return this.searchProductsCart$.asObservable();
  }

  public goToCart() {
    this.router.navigate(['carrinho']);
  }

  setAuxVariables(cart: Cart, freightSelected: FreightSelected | undefined) {
    this.setHaveProductDiscount(cart);
    this.setValueSubtotal(cart);
    this.setValueDiscount(cart);
    this.setValueTotal(cart, freightSelected);
    this.setCashDiscount(cart);
  }

  setHaveProductDiscount(cart: Cart) {
    let have = false;
    if (cart?.items) {
      cart.items.forEach((c) => {
        if (c?.product?.discount || 0 > 0) {
          have = true;
        }
      });
    }
    cart.haveProductDiscount = have;
  }

  setValueSubtotal(cart: Cart) {
    let total = 0;
    if (cart && cart.items) {
      cart.items.forEach((c) => {
        const product = c?.product || new Product();
        const amount = c.amount || 0;
        let value = product?.value || 0;

        if (product.productPerSquareMeter) {
          value = (c?.customHeight || 0) * (c?.customWidth || 0) * value;
        }

        if (product.isPersiana()) {
          if (c.drive === BlindsOptionsEnum.DRIVE_MOTOR) {
            value += this.productService.getValueMotor();
          }

          if (c.panel === BlindsOptionsEnum.WITH_PANEL) {
            value += this.productService.getValueTotalBando(
              c.customWidth || 0,
              product?.subCategory || ''
            );
          }
        }
        total += amount * value;
      });
    }
    cart.valueSubtotal = total;
  }

  setValueDiscount(cart: Cart) {
    let total = 0;
    if (cart?.items) {
      cart.items.forEach((c) => {
        const product = c?.product || new Product();
        const amount = c.amount || 0;
        let value = product?.value || 0;

        if (product.productPerSquareMeter) {
          value = (c?.customHeight || 0) * (c?.customWidth || 0) * value;
        }

        if (product.isPersiana()) {
          if (c.drive === BlindsOptionsEnum.DRIVE_MOTOR) {
            value += this.productService.getValueMotor();
          }

          if (c.panel === BlindsOptionsEnum.WITH_PANEL) {
            value += this.productService.getValueTotalBando(
              c.customWidth || 0,
              product?.subCategory || ''
            );
          }
        }

        const discount = product?.discount || 0;
        total += amount * ((value * discount) / 100);
      });
    }
    cart.valueDiscount = total;
  }

  setValueTotal(cart: Cart, freightSelected: FreightSelected | undefined) {
    let value = 0;
    if (cart?.items) {
      cart.items.forEach((c) => {
        const amount = c.amount || 0;
        const discount = this.getValueWithDiscount(c) || 0;
        value += amount * discount;
      });
    }

    cart.valueTotalWithoutFreight = value;

    if (value && value > 0) {
      if (freightSelected) {
        const price = freightSelected?.freight?.priceFreight || 0;
        value += price;
      }
    }
    cart.valueTotal = value;
  }

  setCashDiscount(cart: Cart) {
    let value = cart.valueTotalWithoutFreight || 0;
    value = value - (value * environment.cashDiscount) / 100;
    cart.valueCashDiscount = value;
  }

  getValueWithDiscount(cartProduct: CartItem) {
    const product = cartProduct?.product || new Product();
    const discount = product?.discount || 0;
    let value = product?.value || 0;

    if (product.productPerSquareMeter) {
      value =
        (cartProduct?.customHeight || 0) *
        (cartProduct?.customWidth || 0) *
        value;
    }

    if (product.isPersiana()) {
      if (cartProduct.drive === BlindsOptionsEnum.DRIVE_MOTOR) {
        value += this.productService.getValueMotor();
      }

      if (cartProduct.panel === BlindsOptionsEnum.WITH_PANEL) {
        value += this.productService.getValueTotalBando(
          cartProduct.customWidth || 0,
          product?.subCategory || ''
        );
      }
    }

    if (discount > 0) {
      return value - (value * discount) / 100;
    } else {
      return value;
    }
  }

  getValueItem(cartProduct: CartItem, whithDiscount: boolean) {
    const product = cartProduct?.product || new Product();
    let value = product?.value || 0;

    if (product.productPerSquareMeter) {
      value =
        (cartProduct?.customHeight || 0) *
        (cartProduct?.customWidth || 0) *
        value;
    }

    if (product.isPersiana()) {
      if (cartProduct.drive === BlindsOptionsEnum.DRIVE_MOTOR) {
        value += this.productService.getValueMotor();
      }

      if (cartProduct.panel === BlindsOptionsEnum.WITH_PANEL) {
        value += this.productService.getValueTotalBando(
          cartProduct.customWidth || 0,
          product?.subCategory || ''
        );
      }
    }

    if (whithDiscount) {
      let discount = product?.discount || 0;
      if (discount > 0) {
        value = value - (value * discount) / 100;
      }
    }
    return value;
  }

  async generateNewOrder(
    items: Array<CartItem>,
    noLoader: boolean
  ): Promise<any> {
    try {
      const cart: CartFull = await this.get(noLoader);
      if (cart) {
        if (cart?.cartProducts?.length || 0 > 0) {
          await Promise.all(
            (cart?.cartProducts || []).map((item) =>
              this.deleteProduct(item.id || 0)
            )
          );
        }
        await Promise.all(items.map((item) => this.addItem(item, true, false)));
      }
    } catch (err) {
      await this.save(items);
    }
  }
}
