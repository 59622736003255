import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpParams,
} from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { catchError, Observable, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import User from '../../shared/models/user/user';
import { LocalStorageService } from '../local-storage/local-storage.service';
import { TokenService } from '../token/token.service';
import { UserService } from '../user/user.service';

@Injectable({
  providedIn: 'root',
})
export class HttpService {
  public token: any;
  public apiUrl;

  constructor(
    private injector: Injector,
    private http: HttpClient,
    private localStorageService: LocalStorageService,
    private tokenService: TokenService
  ) {
    this.apiUrl = environment.apiUrl;
    this.tokenService.token$.subscribe((res: any) => {
      this.token = res;
    });
  }

  post(serviceName: string, data: any, headers?: HttpHeaders) {
    const options = {
      headers: this.getHeader(headers),
      withCredintials: false,
    };
    return this.http
      .post(
        this.apiUrl + serviceName,
        data ? JSON.stringify(data) : null,
        options
      )
      .pipe(catchError((error) => this.handleAuthError(error)));
  }

  put(serviceName: string, data: any) {
    const options = { headers: this.getHeader(), withCredintials: false };
    return this.http
      .put(this.apiUrl + serviceName, JSON.stringify(data), options)
      .pipe(catchError((error) => this.handleAuthError(error)));
  }

  get(serviceName: string, params?: HttpParams, headers?: HttpHeaders) {
    const options = {
      headers: this.getHeader(headers),
      withCredintials: false,
      params,
    };
    return this.http
      .get(this.apiUrl + serviceName, options)
      .pipe(catchError((error) => this.handleAuthError(error)));
  }

  delete(serviceName: string) {
    const options = {
      headers: this.getHeader(),
      withCredintials: false,
    };
    return this.http
      .delete(this.apiUrl + serviceName, options)
      .pipe(catchError((error) => this.handleAuthError(error)));
  }

  login(user: User) {
    const data = new URLSearchParams({
      username: user.email || '',
      password: user.password || '',
      grant_type: 'password',
    });

    const headersOptions = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization:
          'Basic ' +
          btoa(environment.clientId + ':' + environment.clientSecret),
      },
    };

    return this.http
      .post(environment.authURL, data, headersOptions)
      .pipe(catchError((error) => this.handleAuthError(error)));
  }

  private handleAuthError(error: HttpErrorResponse): Observable<any> {
    const userService = this.injector.get(UserService);
    if (error.status === 401) {
      this.localStorageService.logout();
      this.tokenService.token$.next('');
      userService.goToHome();
    }
    return throwError(() => error);
  }

  getHeader(aditionalHeaders?: HttpHeaders): HttpHeaders {
    let defaultHeaders = new HttpHeaders()
      .set('Access-Control-Allow-Origin', '*')
      .set('Accept', '*/*')
      .set('Content-Type', 'application/json')
      .set('Accept-Encondig', 'gzip, deflate, br')
      .set('X-Custom-Origin', 'AMBIENTA')
      .set('Authorization', this.token ? 'Bearer ' + this.token : '');

    if (!aditionalHeaders) return defaultHeaders;

    return aditionalHeaders.keys().reduce((headers, headerKey) => {
      const headerValue: string | null = aditionalHeaders.get(headerKey);
      if (headerValue) headers = defaultHeaders.set(headerKey, headerValue);
      return headers;
    }, defaultHeaders);
  }
}
