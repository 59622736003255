import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MessageService } from 'primeng/api';
import { UserError } from '../../../../pages/register-user/register-user.component';
import { AuthService } from '../../../../services/auth/auth.service';
import { MetaTagsService } from '../../../../services/meta-tags/meta-tags.service';
import { ToastService } from '../../../../services/toast/toast.service';
import { UserService } from '../../../../services/user/user.service';
import User from '../../../models/user/user';
import {
  validBirthDate,
  validEmail,
  validName,
  validPhone,
} from '../../../utils/validators';
import { UserDataComponent } from '../../user-data/user-data.component';

@Component({
  selector: 'app-data-customers',
  standalone: true,
  imports: [UserDataComponent],
  templateUrl: './data-customers.component.html',
  styleUrl: './data-customers.component.scss',
  providers: [ToastService, MessageService],
})
export class DataCustomersComponent implements OnInit {
  public user: User = new User();
  public userError: UserError = new UserError();

  constructor(
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private metaServices: MetaTagsService,
    private toastService: ToastService,
    private userService: UserService
  ) {
    this.authService.userData$.subscribe((res: any) => {
      this.user = new User();
      if (res) {
        this.user = new User();
        this.user.fromJson(res);
      }
    });
  }

  async ngOnInit() {
    this.activatedRoute.url.subscribe((url) => {
      this.metaServices.configTagPages(
        MetaTagsService.PAGE_CUSTOMER_AREA_DATA,
        url[0]?.path,
        '',
        '',
        null
      );
    });
  }

  onClickUpdate() {
    const validUser = this.validaUser();
    if (validUser) {
      this.update();
    } else {
      this.toastService.error('É necessário preencher todos os campos!');
    }
  }

  validaUser() {
    let hasError = true;
    this.userError.errorName = !validName(this.user.name || '');
    this.userError.errorLastName = !validName(this.user.lastName || '');
    this.userError.errorEmail = !validEmail(this.user.email || '');
    this.userError.errorPhone = !validPhone(this.user.phone || '');
    this.userError.errorBirthDate = !validBirthDate(this.user.birthDate || '');

    hasError =
      this.userError.errorName ||
      this.userError.errorLastName ||
      this.userError.errorEmail ||
      this.userError.errorPhone ||
      this.userError.errorBirthDate;

    return !hasError;
  }

  async update() {
    try {
      this.user.phone = this.user?.phone?.replace(/\D/g, '');
      await this.userService.update(this.user);
      this.toastService.success('Seus dados foram alterados com sucesso!');
    } catch (error: any) {
      this.toastService.error(error || 'Erro ao alterar seus dados!');
    }
  }
}
